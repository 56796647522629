/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import { Link as RouterLink } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import {
  Stack,
  Typography,
  Card,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing,
  Selection
} from 'devextreme-react/data-grid';

import CloseIcon from '@mui/icons-material/Close';
import PermissionsProfile from '../../../../@core/utils/permissions';
import configAxios from '../../../../services/configAxios';
import PropTypes from 'prop-types';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import LinkProfilePermissions from './LinkProfilePermissions';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import palette from 'src/@core/theme/palette';

const allowedPageSizes = [2, 5, 10];

const Permissions = (props) => {
  const [infosProfile, setInfosProfile] = useState({});
  const [idProfile, setIdProfile] = useState('');
  const [typeUser, setTypeUser] = useState('');
  const [rowsSelected, setRowsSelected] = useState();
  const [openModalLinkProfilePermissions, setOpenModalLinkProfilePermissions] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [permissionsProfile, setPermissionsProfile] = useState([]);
  const [buttonFetchPermissions, setButtonFetchPermissions] = useState(false);

  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  function getPermissions() {
    if (!idProfile || !typeUser) {
      NotificationManager.warning(
        'Selecione o perfil e o tipo de usuário para buscar as permissões!',
        'Atenção'
      );
      return;
    }

    const id_type_user = typeUser === 'gestao' ? 1 : 2;

    configAxios
      .get(`/users/getPermissions/${idProfile}/${id_type_user}`)
      .then((response) => {
        setInfosProfile(response.data);
        setButtonFetchPermissions(true);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar as permissões, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  function selectedRows(event) {
    setRowsSelected(event.selectedRowsData);
  }

  function HandleDeleteRows() {
    if (rowsSelected.length === 0) {
      NotificationManager.warning('Selecione ao menos um registro para excluir!', 'Atenção');
      return;
    }

    const id_type_user = typeUser === 'gestao' ? 1 : 2;

    configAxios
      .delete(`/users/deletePermissions/${idProfile}/${id_type_user}`, { data: rowsSelected })
      .then(() => {
        NotificationManager.success('Permissões excluídas com sucesso!', 'Sucesso');
        getPermissions(idProfile);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para excluir as permissões, por favor, tente novamente!',
          'Atenção'
        );
      });
  }

  // Função para alterar o tipo do perfil
  function HandleInputChangeProfile(event) {
    setIdProfile(event.target.value);
    setInfosProfile({});
    setButtonFetchPermissions(false);
  }

  // Função para controlar o tipo de usuário
  function HandleInputChangeTypeUser(event) {
    const permissionsProfileFilter = [];
    const tempPermissionsProfile = PermissionsProfile();

    if (event.target.value === 'parceiro') {
      tempPermissionsProfile.map((item) => {
        if (item.typeUser === 'parceiro') {
          permissionsProfileFilter.push(item);
        }
      });
    } else {
      tempPermissionsProfile.map((item) => {
        if (item.typeUser === 'parceiro' || item.typeUser === 'gestao') {
          permissionsProfileFilter.push(item);
        }
      });
    }

    setInfosProfile({});
    setButtonFetchPermissions(false);

    setTypeUser(event.target.value);
    setPermissionsProfile(permissionsProfileFilter);
  }

  // função para abrir o modal
  function linkProfilePermissions() {
    setOpenModalLinkProfilePermissions(true);
  }

  // Função para excluir permissões
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <RenderPage title="Permissões" id="permissions">
      <>
        {/* dxclusão */}
        <div>
          <BootstrapDialog
            onClose={() => {
              setModalDeleteOpen(false);
              getPermissions();
            }}
            aria-labelledby="customized-dialog-title"
            fullWidth
            maxWidth={'sm'}
            open={modalDeleteOpen}>
            <BootstrapDialogTitle
              style={{ color: palette.primary.contrastText }}
              id="customized-dialog-title"
              onClose={() => {
                setModalDeleteOpen(false);
                getPermissions(idProfile);
              }}>
              Excluir permissões
            </BootstrapDialogTitle>

            <DialogContent dividers>
              <Typography variant="subtitle2" style={{ color: palette.primary.contrastText }}>
                Deseja realmente excluir as permissões selecionadas?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  HandleDeleteRows();
                  setModalDeleteOpen(false);
                }}
                size="medium"
                color="success"
                startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}>
                Sim
              </Button>
              <Button
                onClick={() => {
                  setModalDeleteOpen(false);
                  getPermissions(idProfile);
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Não
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
        {/* VIncular permissão */}
        <LinkProfilePermissions
          openModal={openModalLinkProfilePermissions}
          setOpenModal={setOpenModalLinkProfilePermissions}
          functionGrid={getPermissions}
          otherInfos={infosProfile}
          idProfile={idProfile}
          typeUser={typeUser}
          permissionsProfile={permissionsProfile}
          setPermissionsProfile={setInfosProfile}
        />
        {/* Codigo da pagina */}
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Controle de permissões
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar permissões
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/dashboard"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>

        <Card>
          <CustomGrid>
            <CustomInfosForm style={{ color: palette.primary.contrastText }}>
              Campos obrigatórios possuem asterisco (*)
            </CustomInfosForm>
            {/* Botoes para buscar as permissões */}
            <Stack direction="row" alignItems="center" justifyContent="start" sx={{ mt: 2, mb: 3 }}>
              <Grid container>
                <Grid item xs={12} md={2.5} sx={isMobile ? { mr: 0 } : { mr: 2 }}>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Selecione o tipo de usuário *
                  </InputLabel>
                  <Select
                    style={{ color: palette.primary.contrastText }}
                    name="typeUser"
                    fullWidth
                    size="small"
                    required
                    value={typeUser || ''}
                    onChange={(event) => HandleInputChangeTypeUser(event)}>
                    <MenuItem style={{ color: palette.primary.contrastText }} value={'gestao'}>
                      Usuário da gestão
                    </MenuItem>
                    <MenuItem style={{ color: palette.primary.contrastText }} value={'parceiro'}>
                      Usuário parceiro
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={2} sx={isMobile ? { mt: 1 } : { mt: 0 }}>
                  <InputLabel id="label" sx={{ fontSize: '13px' }}>
                    Selecione o perfil *
                  </InputLabel>
                  <Select
                    style={{ color: palette.primary.contrastText }}
                    name="idProfile"
                    fullWidth
                    size="small"
                    required
                    value={idProfile || ''}
                    onChange={(event) => HandleInputChangeProfile(event)}>
                    <MenuItem style={{ color: palette.primary.contrastText }} value={1}>
                      Administrador
                    </MenuItem>
                    <MenuItem style={{ color: palette.primary.contrastText }} value={2}>
                      Usuário
                    </MenuItem>
                  </Select>
                </Grid>
                {!isMobile && (
                  <Grid item xs={12} md={2} sx={{ mt: 2.6, ml: 2 }}>
                    <Button
                      variant="contained"
                      onClick={getPermissions}
                      sx={{ mr: 1 }}
                      startIcon={<Iconify icon="eva:funnel-fill" />}>
                      Buscar
                    </Button>
                  </Grid>
                )}
                {isMobile && (
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mt: 1 }}>
                      <Button
                        variant="contained"
                        onClick={getPermissions}
                        sx={{ mr: 1 }}
                        startIcon={<Iconify icon="eva:funnel-fill" />}>
                        Buscar
                      </Button>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Stack>
            {/* Botoes de ação de vincular e excluir */}
            <Stack direction="row" alignItems="center" justifyContent="start">
              {!isMobile && (
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                        Adicionar e/ou remover permissões
                      </CustomInfosForm>
                    </Grid>
                  </Grid>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mb: 1 }}>
                    <Button
                      variant="contained"
                      onClick={linkProfilePermissions}
                      disabled={!buttonFetchPermissions}
                      startIcon={<Iconify icon="eva:people-outline" />}>
                      Vincular
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      disabled={!rowsSelected}
                      onClick={() => {
                        setModalDeleteOpen(true);
                      }}
                      sx={{ ml: 1 }}
                      startIcon={<Iconify icon="eva:trash-2-outline" />}>
                      Excluir
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
            {isMobile && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 1 }}>
                  <Button
                    variant="contained"
                    onClick={linkProfilePermissions}
                    disabled={!buttonFetchPermissions}
                    startIcon={<Iconify icon="eva:people-outline" />}>
                    Vincular
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    disabled={!rowsSelected}
                    onClick={() => {
                      setModalDeleteOpen(true);
                    }}
                    sx={{ ml: 1 }}
                    startIcon={<Iconify icon="eva:trash-2-outline" />}>
                    Excluir
                  </Button>
                </Stack>
              </Grid>
            )}
            {/* Grid que carrega as permissões */}
            <DataGrid
              dataSource={infosProfile}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onSelectionChanged={selectedRows}
              noDataText={'Não foram encontradas permissões'}>
              <Selection mode="multiple" />
              <Column dataField="name" caption="Nome" />
              <Column dataField="path" caption="Caminho" />
              <Column dataField="descprofile" caption="Perfil" />
              <SearchPanel visible={true} width={240} placeholder="Procurar..." />
              {!isMobile && (
                <GroupPanel visible={true} emptyPanelText="Arraste as colunas aqui para agrupar" />
              )}
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser
                enabled={true}
                title={'Escolher uma coluna'}
                emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
              />
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} allowSearch={true} />
            </DataGrid>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default Permissions;
