import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { isMobile } from 'react-device-detect';

import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box
} from '@mui/material';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import configAxios from '../../../../services/configAxios';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import palette from 'src/@core/theme/palette';

const addEditAmenities = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');

  const infoStateTemp = useSelector(selectLogin);
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  const [infosAmenities, setInfosAmenities] = useState(state ? { ...state.infosEdit } : {});

  const key = useRef('');

  useEffect(() => {
    if (state === null && pathNameSplice[2] === 'editTables') [navigation('/app/tables')];
  }, []);

  function HandleInputChange(event, autocomplete) {
    if (autocomplete) {
      if (event) {
        setInfosAmenities({ ...infosAmenities, label: event.label, codplano: event.codplano });
        return;
      }
      return;
    }
    setInfosAmenities({ ...infosAmenities, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = infosAmenities.id ? '/amenitie/updateAmenitie' : '/amenitie/addAmenitie';
    configAxios
      .post(router, infosAmenities)
      .then((response) => {
        const message = infosAmenities.id
          ? 'Comodidade atualizada com sucesso!'
          : 'Comodidade inserida com sucesso!';
        NotificationManager.success(message, 'Sucesso');
        key.current = Math.random();
        setInfosAmenities({
          ...infosAmenities,
          id: response.data.id,
          codamenitie: response.data.codamenitie
        });
        const nav = infosAmenities.id
          ? `/app/editAmenities/${infosAmenities.id}`
          : `/app/editAmenities/${response.data.id}`;
        navigation(nav, {
          state: {
            infosEdit: {
              ...infosAmenities,
              id: infosAmenities.id ? infosAmenities.id : response.data.id,
              codamenitie: infosAmenities.codamenitie
                ? infosAmenities.codamenitie
                : response.data.codamenitie
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  return (
    <RenderPage title="Ameneties" id="Amenitie">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Cadastro de comodidades
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para inserir e editar as comodidades
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/Amenities"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                  Campos obrigatórios possuem asterisco (*)
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  {infosAmenities.id && (
                    <Grid item xs={2} md={1}>
                      <TextField
                        name="id"
                        fullWidth
                        label="ID"
                        variant="outlined"
                        size="small"
                        required
                        value={infosAmenities.id || ''}
                        disabled
                      />
                    </Grid>
                  )}
                  {infosAmenities.codamenitie && (
                    <Grid item xs={6} md={2}>
                      <TextField
                        name="codamenitie"
                        fullWidth
                        label="Código da Comodidade"
                        variant="outlined"
                        size="small"
                        required
                        value={infosAmenities.codamenitie || ''}
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={5}>
                    <TextField
                      name="descamenitie"
                      fullWidth
                      label="Descrição da comodidade"
                      variant="outlined"
                      size="small"
                      required
                      value={infosAmenities.descamenitie || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={infoState.tipointegracao !== 'Não possui' ? 3 : 4}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { mt: 0 } : { fontSize: '13px', mt: -2.2 }}>
                      Status *
                    </InputLabel>
                    <Select
                      style={{ color: palette.primary.contrastText }}
                      name="status"
                      fullWidth
                      size="small"
                      required
                      value={infosAmenities.status || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem style={{ color: palette.primary.contrastText }} value={'ATIVO'}>
                        Ativo
                      </MenuItem>
                      <MenuItem style={{ color: palette.primary.contrastText }} value={'INATIVO'}>
                        Inativo
                      </MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Box>
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  sx={{ mr: 2 }}
                  startIcon={<Iconify icon="eva:save-fill" />}>
                  {infosAmenities.id ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/Amenities"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default addEditAmenities;
