/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import { DropzoneArea } from 'mui-file-dropzone';
import { useDebounce } from 'use-debounce';
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid';

import {
  Stack,
  Typography,
  Card,
  Button,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Box
  // Autocomplete
} from '@mui/material';
import { removeAccents, formatDate } from '../../../../@core/utils/utilsFunctions';
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item
} from 'devextreme-react/html-editor';

import '../../../../assets/style/globalStyle.css';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import Iconify from '../../../../@core/theme/Iconify';
import RenderPage from '../../../layouts/RenderPage';
import configAxios from '../../../../services/configAxios';
import ShowDocuments from '../../../components/multiplesComponents/ShowDocuments';
import MoreMenuDocuments from '../../../components/multiplesComponents/MoreMenuDocuments';
import palette from 'src/@core/theme/palette';

const sizeValues = ['4pt', '6pt', '8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana'
];
const headerValues = [false, 1, 2, 3, 4, 5];

const AddEditForm = (props) => {
  const navigation = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  const pathNameSplice = pathname.split('/');
  const [infos, setInfos] = useState(state ? { ...state.infosEdit } : {});
  const [grid] = useState(state ? [{ ...state.infosEdit }] : [{}]);
  const [pdf, setpdf] = useState(null);
  const [openModalPreviewDocument, setOpenModalPreviewDocument] = useState(false);
  const key = useRef('');
  const [value] = useDebounce(key.current, 1000);

  useEffect(() => {
    if (state === null && pathNameSplice === 'editForm') [navigation('/app/forms')];
  }, []);

  function HandleInputChange(event, form, labelProduct) {
    if (labelProduct) {
      if (event) {
        setInfos({ ...infos, label: event.label, codplano: event.codplano });
        return;
      }
      return;
    }
    if (form) {
      setInfos({ ...infos, document: event.value });
      return;
    }
    setInfos({ ...infos, [event.target.name]: event.target.value });
  }

  async function PreViewDocument() {
    setpdf();
    const document = {
      document: grid[0].document
    };
    await configAxios
      .post(`/form/preViewDocument/`, document, {
        responseType: 'blob'
      })
      .then(async (result) => {
        const blob = URL.createObjectURL(result.data);
        setpdf(blob);
        setOpenModalPreviewDocument(true);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos para gerar o documento para assinatura! Tente novamente!',
          'Atenção'
        );
      });
  }

  function ActionsButtonsUploads(items) {
    const infos = [];
    infos.push(items.data.data);
    return grid.map((item) => (
      <MoreMenuDocuments
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/forms/deleteFormFile',
          deleteItem: 'o documento',
          functionGrid: clearDocument,
          fileName: item.document,
          id_form: item.id,
          form: true
        }}
      />
    ));
  }

  function clearDocument() {
    grid[0].document = null;
    setInfos({ ...infos, document: null });
  }

  function FormatDate(infos) {
    if (infos.data.data.date_update) {
      let dateFormated = formatDate(infos.data.data.date_update);
      return dateFormated;
    }
  }

  function HandleSubmitDocument(event) {
    if (event.length) {
      const formData = new FormData();

      if (grid[0].document !== null) {
        NotificationManager.warning('Só é permitido anexar um documento!', 'atenção');
        return;
      }

      key.current = Math.random();
      for (let i = 0; i < event.length; i++) {
        formData.append(event[i].name, event[i], removeAccents(event[i].name));
      }
      formData.append('infos', JSON.stringify(infos));

      configAxios
        .post('/forms/createFormFile', formData)
        .then((result) => {
          grid[0].document = result.data.fileName;
          infos.document = result.data.fileName;

          NotificationManager.success('Documento anexado com sucesso!', 'Sucesso');
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para anexar o documento, tente novamente!',
            'Atenção'
          );
        });
    }
  }

  function HandleSubmit(event) {
    event.preventDefault();

    const router = infos.id ? '/forms/updateForm' : '/forms/addForm';
    configAxios
      .post(router, infos)
      .then((response) => {
        if (response.status === 202) {
          NotificationManager.warning(
            'Código do plano já vinculado a outro formulário, verifique!',
            'Atenção'
          );
          return;
        }
        const message = infos.id
          ? 'Formulário atualizado com sucesso!'
          : 'Formulário inserido com sucesso!';
        NotificationManager.success(message, 'Sucesso');
        setInfos({
          ...infos,
          id: response.data.id
        });
        const nav = infos.id ? `/app/editForm/${infos.id}` : `/app/editForm/${response.data.id}`;
        navigation(nav, {
          state: {
            infosEdit: {
              ...infos,
              id: infos.id ? infos.id : response.data.id
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  return (
    <RenderPage title="Formulários" id="formAddEdit">
      <ShowDocuments
        openModal={openModalPreviewDocument}
        url={pdf}
        setOpenModal={setOpenModalPreviewDocument}
      />

      <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
        <Grid container>
          <BreadcrumbsNav {...props} />
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="h4" gutterBottom>
              Formulários
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Utilize as opções abaixo para inserir e editar os formulários
            </Typography>
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          {!isMobile && (
            <Button
              variant="outlined"
              color="error"
              component={RouterLink}
              to="/app/forms"
              startIcon={<Iconify icon="eva:arrow-back-fill" />}>
              Voltar
            </Button>
          )}
        </Stack>
      </Stack>
      <Card>
        <CustomGrid>
          <form onSubmit={HandleSubmit}>
            <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
              <CustomInfosForm>Campos obrigatórios possuem asterisco (*)</CustomInfosForm>
              <CustomGrid>
                {infos.id && infos.document !== null && infos.tpform === 'Documento' && (
                  <Stack direction="row" justifyContent="end" sx={{ mb: 2 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={PreViewDocument}
                      sx={{ textTransform: 'none' }}
                      startIcon={<Iconify icon="eva:printer-outline" />}>
                      Pré visualizar
                    </Button>
                  </Stack>
                )}
                <Grid container spacing={2}>
                  {infos.id && (
                    <Grid item xs={3} md={1} sx={{ mb: 1 }}>
                      <TextField
                        name="id"
                        fullWidth
                        label="ID"
                        variant="outlined"
                        size="small"
                        required
                        value={infos.id || ''}
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={2}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.3 }}>
                      Tipo de formulário *
                    </InputLabel>
                    <Select
                      name="tpform"
                      fullWidth
                      size="small"
                      required
                      value={infos.tpform || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'Editor'}>Editor</MenuItem>
                      <MenuItem value={'Documento'}>Documento</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={infos.tpdoc === 'Contrato' ? 3 : 4}>
                    <TextField
                      name="nome"
                      fullWidth
                      label="Nome do formulário"
                      variant="outlined"
                      size="small"
                      required
                      value={infos.nome || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={infos.tpdoc === 'Contrato' ? 2 : 3}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.3 }}>
                      Selecione o tipo de formulário *
                    </InputLabel>
                    <Select
                      name="tpdoc"
                      fullWidth
                      size="small"
                      required
                      value={infos.tpdoc || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'Proposta de adesão'}>Proposta de adesão</MenuItem>
                      <MenuItem value={'Termo de adesão'}>Termo de adesão</MenuItem>
                      <MenuItem value={'Termo de aceite'}>Termo de aceite</MenuItem>
                      <MenuItem value={'Contrato'}>Contrato</MenuItem>
                      <MenuItem value={'Declaração de saúde'}>Declaração de saúde</MenuItem>
                      <MenuItem value={'Ficha associativa'}>Ficha associativa</MenuItem>
                      <MenuItem value={'Envio de email para assinatura'}>
                        Envio de email para assinatura
                      </MenuItem>
                      <MenuItem value={'Simulação de vendas'}>Simulação de vendas</MenuItem>
                    </Select>
                  </Grid>
                  {infos.tpdoc === 'Contrato' && (
                    <Grid item xs={12} md={2}>
                      <TextField
                        name="codplano"
                        fullWidth
                        label="Código do plano"
                        variant="outlined"
                        size="small"
                        required
                        value={infos.codplano || ''}
                        onChange={(event) => HandleInputChange(event)}
                        inputProps={{ maxLength: 4 }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={3}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: 0 } : { fontSize: '13px', mt: -2.3 }}>
                      Tipo de contratação *
                    </InputLabel>
                    <Select
                      name="tipo"
                      fullWidth
                      size="small"
                      required
                      value={infos.tipo || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem value={'1-INDIVIDUAL/FAMILIAR'}>1-INDIVIDUAL/FAMILIAR</MenuItem>
                      <MenuItem value={'2-COLETIVO EMPRESARIAL'}>2-COLETIVO EMPRESARIAL</MenuItem>
                      <MenuItem value={'3-COLETIVO POR ADESAO'}>3-COLETIVO POR ADESÃO</MenuItem>
                      <MenuItem value={'4-TODOS'}>4-TODOS</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                {infos.id && (
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} md={12}>
                      {infos.document !== null && infos.tpform === 'Documento' && (
                        <DataGrid
                          dataSource={grid}
                          showBorders={true}
                          allowColumnResizing={true}
                          remoteOperations={true}
                          wordWrapEnabled={true}
                          columnAutoWidth={true}
                          key={value}
                          noDataText={'Não foram encontrados registros'}>
                          <Column dataField="document" caption="Nome do documento" />
                          <Column
                            dataField="date_update"
                            caption="Data da última atualização"
                            cellRender={(data) => <FormatDate data={data} />}
                          />
                          <Column
                            dataField="Ações"
                            width={100}
                            allowSorting={false}
                            cellRender={(data) => <ActionsButtonsUploads data={data} />}
                          />
                          <Scrolling rowRenderingMode="virtual"></Scrolling>
                        </DataGrid>
                      )}
                      {infos.tpform === 'Documento' && (
                        <Grid container sx={{ mt: 2 }}>
                          <DropzoneArea
                            acceptedFiles={[
                              'application/vnd.oasis.opendocument.text ',
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                            ]}
                            onChange={(event) => HandleSubmitDocument(event)}
                            dropzoneText="Arraste o documento ou clique para selecionar"
                            fullWidth={true}
                            showAlerts={false}
                            filesLimit={1}
                            showPreviewsInDropzone={false}
                            key={value}
                            getFileLimitExceedMessage={(filesLimit) =>
                              NotificationManager.warning(
                                `Limite de ${filesLimit} arquivos por vez`,
                                'Atenção'
                              )
                            }
                          />
                        </Grid>
                      )}
                      {infos.tpform === 'Editor' && (
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                          <Grid item xs={12} md={12}>
                            <div className="widget-container">
                              <HtmlEditor
                                height="750px"
                                defaultValue={infos.document}
                                valueType="html"
                                onValueChanged={(event) => HandleInputChange(event, true)}>
                                <MediaResizing enabled={true} />
                                <ImageUpload fileUploadMode="base64" />
                                <Toolbar>
                                  <Item name="undo" />
                                  <Item name="redo" />
                                  <Item name="separator" />
                                  <Item name="size" acceptedValues={sizeValues} />
                                  <Item name="font" acceptedValues={fontValues} />
                                  <Item name="separator" />
                                  <Item name="bold" />
                                  <Item name="italic" />
                                  <Item name="strike" />
                                  <Item name="underline" />
                                  <Item name="separator" />
                                  <Item name="alignLeft" />
                                  <Item name="alignCenter" />
                                  <Item name="alignRight" />
                                  <Item name="alignJustify" />
                                  <Item name="separator" />
                                  <Item name="orderedList" />
                                  <Item name="bulletList" />
                                  <Item name="separator" />
                                  <Item name="header" acceptedValues={headerValues} />
                                  <Item name="separator" />
                                  <Item name="color" />
                                  <Item name="background" />
                                  <Item name="separator" />
                                  <Item name="link" />
                                  <Item name="image" />
                                  <Item name="separator" />
                                  <Item name="clear" />
                                  <Item name="codeBlock" />
                                  <Item name="blockquote" />
                                  <Item name="separator" />
                                  <Item name="insertTable" />
                                  <Item name="deleteTable" />
                                  <Item name="insertRowAbove" />
                                  <Item name="insertRowBelow" />
                                  <Item name="deleteRow" />
                                  <Item name="insertColumnLeft" />
                                  <Item name="insertColumnRight" />
                                  <Item name="deleteColumn" />
                                  <Item name="cellProperties" />
                                  <Item name="tableProperties" />
                                </Toolbar>
                              </HtmlEditor>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </CustomGrid>
            </Box>
            <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color="success"
                sx={{ mr: 2 }}
                startIcon={<Iconify icon="eva:save-fill" />}>
                Salvar
              </Button>
              <Button
                variant="contained"
                color="error"
                component={RouterLink}
                to="/app/forms"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </Stack>
          </form>
        </CustomGrid>
      </Card>
    </RenderPage>
  );
};

export default AddEditForm;
