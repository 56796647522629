import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { CustomGrid } from '../../../../assets/style/globalStyle';
import { Stack, Typography, Button, Card, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import configAxios from '../../../../services/configAxios';
import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';

const allowedPageSizes = [5, 10, 20, 30];

const Amenitie = (props) => {
  const [infosAmenities, setInfosAmenities] = useState({});

  useEffect(() => {
    getAmenities();
  }, []);

  function getAmenities() {
    configAxios
      .get('/amenitie/getAmenities')
      .then((response) => {
        setInfosAmenities(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as tabelas, tente novamente!',
          'Atenção'
        );
      });
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          router: 'editAmenities',
          functionGrid: getAmenities,
          otherInfos: item
        }}
      />
    ));
  }

  return (
    <RenderPage title="Comodidades">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Lista de comodidades
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para visualizar as comodidades
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/addAmenities"
              startIcon={<Iconify icon="eva:plus-fill" />}>
              Nova
            </Button>
            {!isMobile && (
              <Button
                variant="outlined"
                onClick={getAmenities}
                sx={{ ml: 1 }}
                startIcon={<Iconify icon="eva:refresh-outline" />}>
                Atualizar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            <DataGrid
              dataSource={infosAmenities}
              showBorders={true}
              remoteOperations={true}
              wordWrapEnabled={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              noDataText={'Não há tabelas cadastradas'}>
              <Column dataField="id" width={70} />
              <Column dataField="codamenitie" caption="Cód. comodidade" width={200} />
              <Column dataField="descamenitie" caption="Descrição" />
              <Column dataField="status" caption="Status" />
              <Column
                dataField="Ações"
                width={100}
                allowSorting={false}
                cellRender={(data) => <ActionsButtons data={data} />}
              />
              <SearchPanel visible={true} width={240} placeholder="Procurar..." />
              {!isMobile && (
                <GroupPanel visible={true} emptyPanelText="Arraste as colunas aqui para agrupar" />
              )}
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <ColumnChooser
                enabled={true}
                title={'Escolher uma coluna'}
                emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
              />
              <ColumnFixing enabled={true} />
              <HeaderFilter visible={true} allowSearch={true} />
            </DataGrid>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default Amenitie;
