import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { selectLogin } from '../../../../store/login/loginSlice';
import { NotificationManager } from 'react-notifications';
import { CustomGrid } from '../../../../assets/style/globalStyle';
import { Stack, Typography, Button, Card, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import configAxios from '../../../../services/configAxios';

const allowedPageSizes = [5, 10, 20, 30];

const Forms = (props) => {
  const [infosForms, setInfosForms] = useState({});
  // const infoStateTemp = useSelector(selectLogin);
  // const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });
  const [infoState] = useState({});

  useEffect(() => {
    getForms();
  }, []);

  function getForms() {
    configAxios
      .get('/forms/listForms')
      .then((response) => {
        setInfosForms(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os formulários, tente novamente!',
          'Atenção'
        );
      });
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          router: 'editForm',
          functionGrid: getForms,
          otherInfos: item
        }}
      />
    ));
  }

  function RenderCodProduct(items) {
    if (items.data.data.tpdoc === 'Contrato') {
      return items.data.data.codplano;
    } else {
      return '-';
    }
  }

  return (
    <RenderPage title="Formulários">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Lista de formulários
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para visualizar os formulários
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/addForm"
              startIcon={<Iconify icon="eva:plus-fill" />}>
              Novo
            </Button>
            {!isMobile && (
              <Button
                variant="outlined"
                onClick={getForms}
                sx={{ ml: 1 }}
                startIcon={<Iconify icon="eva:refresh-outline" />}>
                Atualizar
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CustomGrid>
            {infoState.tipo !== 'Complexo' && (
              <DataGrid
                dataSource={infosForms}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                noDataText={'Não há formulários cadastrados'}>
                <Column dataField="id" />
                <Column dataField="nome" caption="Nome do formulário" />
                <Column dataField="tpdoc" caption="Tipo de formulário" />
                <Column
                  dataField="Código do produto"
                  cellRender={(data) => <RenderCodProduct data={data} />}
                />

                <Column dataField="tipo" caption="Tipo de contratação" />
                <Column
                  dataField="Ações"
                  width={100}
                  allowSorting={false}
                  cellRender={(data) => <ActionsButtons data={data} />}
                />
                <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                {!isMobile && (
                  <GroupPanel
                    visible={true}
                    emptyPanelText="Arraste as colunas aqui para agrupar"
                  />
                )}
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <ColumnChooser
                  enabled={true}
                  title={'Escolher uma coluna'}
                  emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
              </DataGrid>
            )}
            {infoState.tipo === 'Complexo' && (
              <DataGrid
                dataSource={infosForms}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                noDataText={'Não há formulários cadastrados'}>
                <Column dataField="id" />
                <Column dataField="nome" caption="Nome do formulário" />
                <Column dataField="tpdoc" caption="Tipo de formulário" />
                <Column dataField="tipo" caption="Tipo de contratação" />
                <Column
                  dataField="Ações"
                  width={100}
                  allowSorting={false}
                  cellRender={(data) => <ActionsButtons data={data} />}
                />
                <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                {!isMobile && (
                  <GroupPanel
                    visible={true}
                    emptyPanelText="Arraste as colunas aqui para agrupar"
                  />
                )}
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <ColumnChooser
                  enabled={true}
                  title={'Escolher uma coluna'}
                  emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
              </DataGrid>
            )}
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default Forms;
