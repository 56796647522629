/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { NotificationManager } from 'react-notifications';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField
} from '@mui/material';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';

import palette from 'src/@core/theme/palette';

const CopyItems = (props) => {
  const [infosItem, setInfosItem] = useState({});

  const [inputFields, setInputFields] = useState({
    faixa1: '0',
    faixa2: '999',
    codtab: props.otherInfos.codtab
  });

  useEffect(() => {
    if (props.infosItemEdit.length > 0) {
      let mesref = props.infosItemEdit[0].mesref;
      let year = mesref.toString().slice(0, 4);
      let month = mesref.toString().slice(4, 6);
      setInfosItem({
        id: props.infosItemEdit[0].id,
        mesref: year + '-' + month,
        valor: props.infosItemEdit[0].valor,
        faixa1: props.infosItemEdit[0].faixa1,
        faixa2: props.infosItemEdit[0].faixa2
      });
    }
  }, [props.infosItemEdit]);

  function HandleInputChangeCreate(event) {
    setInputFields({ ...inputFields, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();

    let route = '/tables/addValPlan';
    let message = 'Item inserido com sucesso!';
    configAxios
      .post(route, inputFields)
      .then(() => {
        props.setOpenModal(false);
        props.functionGrid();
        setInputFields({});
        NotificationManager.success(message, 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth={'md'}
        open={props.openModal}>
        <BootstrapDialogTitle
          style={{ color: palette.primary.contrastText }}
          id="customized-dialog-title">
          Adicionar itens na tabela de valores
        </BootstrapDialogTitle>

        <form onSubmit={HandleSubmit}>
          <DialogContent dividers>
            <CustomInfosForm style={{ color: palette.primary.contrastText }}>
              Campos obrigatórios possuem asterisco (*)
            </CustomInfosForm>
            <div>
              <Grid
                container
                spacing={2}
                sx={{ mb: 1, mt: 1, input: { color: palette.primary.contrastText } }}>
                <Grid item xs={12} md={3}>
                  <TextField
                    name="mesref"
                    fullWidth
                    label="Mês de referência"
                    variant="outlined"
                    size="small"
                    required
                    type="month"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={inputFields.mesref || ''}
                    onChange={(event) => HandleInputChangeCreate(event)}
                  />
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <TextField
                    name="valor"
                    fullWidth
                    label="Valor R$"
                    variant="outlined"
                    size="small"
                    type="number"
                    required
                    value={inputFields.valor || ''}
                    onChange={(event) => HandleInputChangeCreate(event)}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="success"
              variant="contained"
              type="submit"
              startIcon={<Iconify icon="eva:save-fill" />}>
              {infosItem.id ? 'Atualizar' : 'Salvar'}
            </Button>
            <Button
              onClick={() => {
                props.setOpenModal(false);
                props.functionGrid();
                setInfosItem({});
                setInputFields([{ mesref: '', faixa1: '', faixa2: '', valor: '' }]);
              }}
              color="error"
              variant="contained"
              startIcon={<Iconify icon="eva:close-circle-fill" />}>
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default CopyItems;
