import React, { useState } from 'react';
import { CustomInfosForm, CustomGrid, CustomItemForm } from '../../../../assets/style/globalStyle';
import { useDispatch, useSelector } from 'react-redux';
import { avatar, selectLogin, nameUser } from '../../../../store/login/loginSlice';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { Link as RouterLink } from 'react-router-dom';
import {
  Stack,
  Typography,
  Grid,
  Card,
  TextField,
  Button,
  Avatar,
  InputAdornment,
  IconButton
} from '@mui/material';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import avatar1 from '../../../../assets/images/avatar/avatar1.jpeg';
import avatar2 from '../../../../assets/images/avatar/avatar2.jpeg';
import avatar3 from '../../../../assets/images/avatar/avatar3.jpeg';
import avatar4 from '../../../../assets/images/avatar/avatar4.jpeg';
import avatar5 from '../../../../assets/images/avatar/avatar5.jpeg';
import avatar6 from '../../../../assets/images/avatar/avatar6.jpeg';
import avatar7 from '../../../../assets/images/avatar/avatar7.jpeg';
import avatar8 from '../../../../assets/images/avatar/avatar8.jpeg';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import configAxios from '../../../../services/configAxios';
import palette from 'src/@core/theme/palette';
const Account = (props) => {
  const dispatch = useDispatch();
  const infos = useSelector(selectLogin);
  const [infosUser, setInfosUser] = useState({ ...infos.userLoggedIn[0] });
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const avatares = [
    {
      avatar: avatar1
    },
    {
      avatar: avatar2
    },
    {
      avatar: avatar3
    },
    {
      avatar: avatar4
    },
    {
      avatar: avatar5
    },
    {
      avatar: avatar6
    },
    {
      avatar: avatar7
    },
    {
      avatar: avatar8
    }
  ];

  function HandleAvatar(event) {
    configAxios
      .post('account/changeAvatar', {
        avatar: event,
        id_type_user: infosUser.id_type_user,
        idUser: infosUser.id
      })
      .then(() => {
        dispatch(avatar(event));
        NotificationManager.success('Avatar atualizado com sucesso!', 'Sucesso');
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atualizar o avatar, tente novamente',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event, validPassword) {
    if (validPassword) {
      setPassword(event.target.value);
      return;
    }
    setInfosUser({ ...infosUser, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    if (password) {
      infosUser.senha = password;
    } else {
      delete infosUser.senha;
    }
    delete infosUser.logo;
    configAxios
      .post('/account/updateAccount', { infosUser })
      .then(() => {
        NotificationManager.success('Informações atualizadas com sucesso!', 'Sucesso');
        dispatch(nameUser(infosUser.nome));
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }
  console.log(infosUser);

  return (
    <RenderPage title="Minha conta">
      <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
        <Grid container>
          <BreadcrumbsNav {...props} />
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="h4" gutterBottom>
              Minha conta
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Visualize e edite as suas informações abaixo
            </Typography>
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          {!isMobile && (
            <Button
              variant="outlined"
              color="error"
              component={RouterLink}
              to="/app/dashboard"
              startIcon={<Iconify icon="eva:arrow-back-fill" />}>
              Voltar
            </Button>
          )}
        </Stack>
      </Stack>
      <Card>
        <CustomGrid>
          <form onSubmit={HandleSubmit}>
            <CustomInfosForm style={{ color: palette.primary.contrastText }}>
              Campos obrigatórios possuem asterisco (*)
            </CustomInfosForm>
            {/*  */}
            <Grid container sx={{ mt: 2 }}>
              <Grid
                item
                sx={{ input: { color: palette.primary.contrastText }, mr: 1 }}
                xs={11}
                md={4}>
                <TextField
                  name="nome"
                  fullWidth
                  label="Nome completo"
                  variant="outlined"
                  size="small"
                  required
                  value={infosUser.nome || ''}
                  onChange={(event) => HandleInputChange(event)}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={11} md={4} sx={{ mr: 1 }}>
                <TextField
                  name="email"
                  fullWidth
                  label="Email"
                  required
                  disabled
                  size="small"
                  type="email"
                  variant="outlined"
                  value={infosUser.email || ''}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={11} md={3.5}>
                <TextField
                  name="senha"
                  fullWidth
                  label="Senha (em branco não altera)"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(event) => HandleInputChange(event, true)}
                  value={password || ''}
                  inputProps={{ maxLength: 50 }}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ mt: 1, mb: 3, input: { color: palette.primary.contrastText } }}>
              <Grid item xs={12} md={4}>
                <TextField
                  name="id_type_user"
                  fullWidth
                  label="Tipo de usuário"
                  variant="outlined"
                  size="small"
                  value={infosUser.id_profile === 1 ? 'Administrador' : 'Usuário'}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="id_type_user"
                  fullWidth
                  label="Administrativo/Parceiro"
                  disabled
                  size="small"
                  variant="outlined"
                  value={infosUser.id_type_user === 1 ? 'Administrativo' : 'Parceiro'}
                />
              </Grid>
              <Grid item xs={12} md={3.5}>
                <TextField
                  name="status"
                  fullWidth
                  label="Status"
                  size="small"
                  variant="outlined"
                  disabled
                  value={infosUser.ativo === true ? 'Ativo' : 'Inativo'}
                />
              </Grid>
            </Grid>
            <CustomItemForm>
              <strong>Selecione o seu avatar:</strong>
            </CustomItemForm>
            <Grid item xs={12}>
              {avatares.map((option) => (
                <Button
                  key={option.avatar}
                  onClick={() => HandleAvatar(option.avatar)}
                  // sx={{ mt: 0.5 }}
                >
                  <Avatar
                    key={option.avatar}
                    src={option.avatar}
                    alt="Avatar"
                    sx={{ width: 86, height: 86 }}
                  />
                </Button>
              ))}
            </Grid>
            <Stack direction="row" justifyContent="end" sx={{ mt: 4 }}>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color="success"
                sx={{ mr: 2 }}
                startIcon={<Iconify icon="eva:save-fill" />}>
                Atualizar
              </Button>
              <Button
                variant="contained"
                color="error"
                component={RouterLink}
                to="/app/dashboard"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </Stack>
          </form>
        </CustomGrid>
      </Card>
    </RenderPage>
  );
};
export default Account;
