/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
// import { selectLogin } from '../../../../store/login/loginSlice';
// import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';

import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import {
  Stack,
  Typography,
  Card,
  Button,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Box
} from '@mui/material';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import configAxios from '../../../../services/configAxios';

import LinkAmenities from '../partners/LinkAmenities';
import LinkSocialMedia from '../partners/LinkSocialMedia';
import LinkOpening from '../partners/LinkOpening';
import LinkCategory from '../partners/LinkCategory';

import InputMask from 'react-input-mask';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import palette from 'src/@core/theme/palette';

const MySpace = (props) => {
  const infos = useSelector(selectLogin);
  const infosUser = { ...infos.userLoggedIn[0] };

  const navigation = useNavigate();

  const [infosPartners, setInfosPartners] = useState({});

  const [Edit, setEdit] = useState(false);

  const [openModalAmenities, setOpenModalAmenities] = useState(false);
  const [openModalSocialMedia, setOpenModalSocialMedia] = useState(false);
  const [openModalOpening, setOpenModalOpening] = useState(false);
  const [openModalCategory, setOpenModalCategory] = useState(false);
  const [value, setValue] = useState('1');

  const [infosSocialMediaEstablishment, setInfosSocialMediaEstablishment] = useState({});
  const [infosAmenitiesEstablishment, setInfosAmenitiesEstablishment] = useState({});
  const [infosOpening, setInfosOpening] = useState({});
  const [infosCategoryEstablishment, setInfosCategoryEstablishment] = useState({});

  const [editInfos, setEditInfos] = useState({});

  const allowedPageSizes = [5, 10, 20, 30];

  useEffect(() => {
    getPrest();

    if (infosUser.id_establishment) {
      getAmenitiesEstablishment();
      getSocialMediaEstablishment();
      getOpeningEstablishment();
      getCategoryEstablishment();
    }
  }, []);

  // carrega os parceiros
  function getPrest() {
    configAxios
      .get(`/establishments/filterSearchEstablishment/${infosUser.id_establishment}`)
      .then((response) => {
        setInfosPartners(response.data.Header[0]);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os parceiros, tente novamente!',
          'Atenção'
        );
      });
  }

  // Gets Listas Parceiros

  function getAmenitiesEstablishment() {
    configAxios
      .get(`/establishments/listAmenitieEstablishment/${infosUser.id_establishment}`)
      .then((response) => {
        setInfosAmenitiesEstablishment(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as comodidades no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  async function getSocialMediaEstablishment() {
    configAxios
      .get(`/establishments/listSocialEstablishment/${infosUser.id_establishment}`)
      .then((response) => {
        setInfosSocialMediaEstablishment(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function getOpeningEstablishment() {
    configAxios
      .get(`/establishments/listOpeningHours/${infosUser.id_establishment}`)
      .then((response) => {
        setInfosOpening(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os repasses do prestador no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function getCategoryEstablishment() {
    configAxios
      .get(`/establishments/listCategorysEstablishment/${infosUser.id_establishment}`)
      .then((response) => {
        console.log(response.data);
        setInfosCategoryEstablishment(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar as categorias vinculadas do prestador no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  // Funcoes de comodidades

  function LinkItemsModalAmenities() {
    setOpenModalAmenities(true);
  }

  function ActionsButtonsAmenities(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/establishments/deleteAmenitieEstablishment',
          deleteItem: 'a comodidade do parceiro',
          functionGrid: getAmenitiesEstablishment,
          disabledEdit: true,
          otherInfos: item
        }}
      />
    ));
  }

  //  Funcoes Rede Social

  function LinkItemsModalSocialMedia() {
    setOpenModalSocialMedia(true);
    setEdit(false);
  }

  function EditItemsModalSocialMedia(event) {
    setOpenModalSocialMedia(true);
    setEdit(true);
    const editInfos = infosSocialMediaEstablishment.find((element) => element.id === event);
    setEditInfos(editInfos);
  }

  function ActionsButtonsSocialMedia(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          functionModal: EditItemsModalSocialMedia,
          deleteRouter: '/establishments/deleteSocialEstablishment',
          deleteItem: 'a rede social do parceiro',
          functionGrid: getSocialMediaEstablishment,
          otherInfos: item
        }}
      />
    ));
  }

  // Funções Horario Funcionamento

  function LinkItemsModalOpening() {
    setOpenModalOpening(true);
    setEdit(false);
  }

  function EditItemsModalOpening(event) {
    setOpenModalOpening(true);
    setEdit(true);
    const editInfos = infosOpening.find((element) => element.id === event);
    setEditInfos(editInfos);
  }

  function ActionsButtonsOpening(items) {
    const infos = [];
    infos.push(items.data.data);

    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          functionModal: EditItemsModalOpening,
          deleteRouter: '/establishments/deleteOpeningHours',
          deleteItem: 'o horario do parceiro',
          functionGrid: getOpeningEstablishment,
          otherInfos: item
        }}
      />
    ));
  }

  // Funções categorias

  function LinkItemsModalCategory() {
    setOpenModalCategory(true);
    setEdit(false);
  }

  function ActionsButtonsCategory(items) {
    const infos = [];
    infos.push(items.data.data);

    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          deleteRouter: '/establishments/deleteCategoryEstablishment',
          deleteItem: 'a categoria do parceiro',
          functionGrid: getCategoryEstablishment,
          disabledEdit: true,
          otherInfos: item
        }}
      />
    ));
  }

  // Outras funções

  function handleTabsChange(event, newValue) {
    setValue(newValue);
  }

  async function getLocation(items) {
    await axios
      .get(
        `https://geocode.search.hereapi.com/v1/geocode?q=${infosPartners.endest}+${infosPartners.numres}+${infosPartners.bairroest}+${infosPartners.cidest}+${infosPartners.ufest}&apiKey=xYEA9OokpHBPn-BHpLyq-t6Aiq6vKlEW6edBA-sEAR8`
      )
      .then((response) => {
        HandleInputChangeNumber({ ...response.data.items[0].position, ...items }, true);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os endereços no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event, cep, autocomplete) {
    if (autocomplete) {
      if (event) {
        setInfosPartners({
          ...infosPartners,
          codcategory: event.codcategory
        });
        return;
      }
      return;
    }

    if (cep) {
      const data = {
        endest: event.rua,
        bairroest: event.bairro,
        cidest: event.cidade,
        ufest: event.estado
      };
      setInfosPartners({ ...infosPartners, ...data });
      return;
    }

    setInfosPartners({ ...infosPartners, [event.target.name]: event.target.value });
  }

  function HandleInputChangeNumber(event, number) {
    if (number) {
      if (event) {
        setInfosPartners({
          ...infosPartners,
          latitude: event.lat,
          longitude: event.lng
        });
      }
      return;
    }

    setInfosPartners({ ...infosPartners, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();
    const router = infosPartners.id
      ? '/establishments/updateEstablishments'
      : '/establishments/addEstablishments';
    configAxios
      .post(router, infosPartners)
      .then((response) => {
        const message = infosPartners.id
          ? 'Parceiro atualizado com sucesso!'
          : 'Parceiro inserido com sucesso!';
        NotificationManager.success(message, 'Sucesso');
        setInfosPartners({
          ...infosPartners,
          id: response.data.id
        });
        const nav = infosPartners.id ? `/app/dashboard` : `/app/dashboard`;
        navigation(nav, {
          state: {
            infosEdit: {
              ...infosPartners,
              id: infosPartners.id ? infosPartners.id : response.data.id
            }
          }
        });
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades atender a requisição no momento, tente novamente!',
          'Atenção'
        );
      });
  }

  return (
    <RenderPage title="Parceiros" id="partners">
      <>
        <LinkAmenities
          openModal={openModalAmenities}
          setOpenModal={setOpenModalAmenities}
          functionGrid={getAmenitiesEstablishment}
          otherInfos={infosPartners}
        />

        <LinkSocialMedia
          openModal={openModalSocialMedia}
          setOpenModal={setOpenModalSocialMedia}
          functionGrid={getSocialMediaEstablishment}
          otherInfos={infosPartners}
          editMode={Edit}
          SocialInfos={editInfos}
        />

        <LinkOpening
          openModal={openModalOpening}
          setOpenModal={setOpenModalOpening}
          functionGrid={getOpeningEstablishment}
          otherInfos={infosPartners}
          editMode={Edit}
          hoursInfos={editInfos}
        />

        <LinkCategory
          openModal={openModalCategory}
          setOpenModal={setOpenModalCategory}
          functionGrid={getOpeningEstablishment}
          otherInfos={infosPartners}
        />

        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Meu espaço
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Utilize as opções abaixo para editar as informações do estabelecimento
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/dashboard"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>

        <Card>
          <CustomGrid>
            <form onSubmit={HandleSubmit}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                  Campos obrigatórios possuem asterisco (*)
                </CustomInfosForm>

                {/* Dados de ID  */}
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  {infosUser.id_establishment ? (
                    <>
                      <Grid item xs={12} md={2}>
                        <TextField
                          name="id"
                          fullWidth
                          label="ID"
                          variant="outlined"
                          size="small"
                          disabled
                          value={infosUser.id_establishment || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2.5}>
                        <TextField
                          name="codest"
                          fullWidth
                          label="Cod. Parceiro"
                          variant="outlined"
                          size="small"
                          disabled
                          value={infosPartners.codest || ''}
                          onChange={(event) => HandleInputChange(event)}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  <Grid item xs={12} md={3}>
                    <InputLabel
                      id="label"
                      sx={isMobile ? { fontSize: '13px', mt: -1 } : { fontSize: '13px', mt: -2.2 }}>
                      Status *
                    </InputLabel>
                    <Select
                      sx={{ color: palette.primary.contrastText }}
                      name="status"
                      fullWidth
                      size="small"
                      disabled
                      value={infosPartners.status || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'ATIVO'}>
                        Ativo
                      </MenuItem>
                      <MenuItem sx={{ color: palette.primary.contrastText }} value={'INATIVO'}>
                        Inativo
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="datacad"
                      fullWidth
                      label="Data de início"
                      variant="outlined"
                      size="small"
                      value={infosPartners.datacad || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                      disabled
                    />
                  </Grid>
                </Grid>

                {/* Dados de Identificação */}
                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 25 }} />
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  <Grid item xs={12} md={5}>
                    <TextField
                      name="fantasiaest"
                      fullWidth
                      label="Nome fantasia"
                      variant="outlined"
                      size="small"
                      value={infosPartners.fantasiaest || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      name="cnpjcpf"
                      fullWidth
                      label="CNPJ"
                      variant="outlined"
                      size="small"
                      value={infosPartners.cnpjcpf || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                      disabled
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={6}>
                    <Autocomplete
                      sx={{ color: palette.primary.contrastText }}
                      options={infosCategories}
                      renderOption={(props, option) => (
                        <li style={{ color: palette.primary.contrastText }} {...props}>
                          {option.label}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        value ? option.label === value : option.label === ''
                      }
                      onChange={(event, value) => HandleInputChange1(value, { autocomplete: true })}
                      value={rowsSelected.categoria || ''}
                      defaultValue=""
                      renderInput={(params) => (
                        <TextField
                          sx={{ color: palette.primary.contrastText }}
                          {...params}
                          label="Categoria principal"
                          name="categoria"
                          fullWidth
                          size="small"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Grid> */}
                </Grid>

                {/* Dados de Contato  */}
                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 25 }} />
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="(99)9999-9999"
                      value={infosPartners.telefone || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="telefone"
                          fullWidth
                          label="Telefone"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="(99)99999-9999"
                      value={infosPartners.celular || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="celular"
                          fullWidth
                          label="Celular"
                          variant="outlined"
                          size="small"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="email"
                      fullWidth
                      label="Email"
                      variant="outlined"
                      size="small"
                      type="email"
                      required
                      value={infosPartners.email || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="nomeresponsavel"
                      fullWidth
                      label="Nome Responsável"
                      variant="outlined"
                      size="small"
                      disabled
                      value={infosPartners.nomeresponsavel || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                </Grid>

                {/* Endereço de Contato  */}
                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 25 }} />
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    input: { color: palette.primary.contrastText }
                  }}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="cepest"
                      fullWidth
                      label="CEP"
                      variant="outlined"
                      size="small"
                      value={infosPartners.cepest || ''}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) => HandleInputChange(event)}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="endest"
                      fullWidth
                      label="Endereço"
                      variant="outlined"
                      size="small"
                      disabled
                      value={infosPartners.endest || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="numres"
                      fullWidth
                      label="Número"
                      variant="outlined"
                      size="small"
                      type="number"
                      disabled
                      value={infosPartners.numres || ''}
                      onChange={(event) => HandleInputChange(event)}
                      onBlur={(event) => getLocation(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="compleres"
                      fullWidth
                      label="Complemento"
                      variant="outlined"
                      size="small"
                      value={infosPartners.compleres || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      name="bairroest"
                      fullWidth
                      label="Bairro"
                      variant="outlined"
                      size="small"
                      disabled
                      value={infosPartners.bairroest || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      name="cidest"
                      fullWidth
                      label="Cidade"
                      variant="outlined"
                      size="small"
                      disabled
                      value={infosPartners.cidest || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      name="ufest"
                      fullWidth
                      label="estado"
                      variant="outlined"
                      size="small"
                      disabled
                      value={infosPartners.ufest || ''}
                      onChange={(event) => HandleInputChange(event)}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="latitude"
                      fullWidth
                      label="latitude"
                      variant="outlined"
                      size="small"
                      disabled
                      value={infosPartners.latitude || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      name="longitude"
                      fullWidth
                      label="Longitude"
                      variant="outlined"
                      size="small"
                      disabled
                      value={infosPartners.longitude || ''}
                      onChange={(event) => HandleInputChange(event)}
                    />
                  </Grid>
                </Grid>

                {/* Endereço de Contato  */}
                <CustomInfosForm style={{ color: palette.primary.contrastText, marginTop: 25 }} />
              </Box>
              {/* Links do parceiro */}
              {infosUser.id_establishment && (
                <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200' }}>
                  <TabContext value={value} variant="scrollable" scrollButtons="auto">
                    <Box sx={{ mt: 3, p: 1 }}>
                      <TabList onChange={handleTabsChange} aria-label="BrokersTabs">
                        <Tab label="Comodidades" value="1" />
                        <Tab label="Redes Sociais" value="2" />
                        <Tab label="Horário de func." value="3" />
                        <Tab label="Cad. de categorias" value="4" />
                      </TabList>
                    </Box>

                    {/* Cadastro de comodidades */}
                    <TabPanel value="1">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item>
                              <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                                Adicionar e/ou remover comodidades
                              </CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinkItemsModalAmenities}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getAmenitiesEstablishment}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 3 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinkItemsModalAmenities}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosAmenitiesEstablishment}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há comodidades cadastradas'}>
                        <Column
                          dataField="codamenitie"
                          caption="Código da comodidade"
                          width={200}
                        />
                        <Column dataField="descamenitie" caption="Descriçāo" />
                        <Column dataField="status" caption="Status" />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsAmenities data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>

                    {/* Cadastro de rede social */}
                    <TabPanel value="2">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item>
                              <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                                Adicionar e/ou remover redes sociais
                              </CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinkItemsModalSocialMedia}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getSocialMediaEstablishment}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 3 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinkItemsModalSocialMedia(false)}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosSocialMediaEstablishment}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há endereços cadastrados'}>
                        <Column dataField="descsocial" caption="Descriçāo" />
                        <Column dataField="link" caption="Link" />
                        <Column dataField="status" width={120} caption="Status" />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsSocialMedia data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>

                    {/* Cadastro de Horarios */}
                    <TabPanel value="3">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item>
                              <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                                Adicionar e/ou remover os horarios de funcionamento do parceiro
                              </CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinkItemsModalOpening}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getOpeningEstablishment}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 3 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinkItemsModalOpening}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosOpening}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há repasses cadastrados'}>
                        <Column dataField="weekDay" caption="Dia Semana" />
                        <Column dataField="openMorning" caption="Ini. manhã" />
                        <Column dataField="closeMorning" caption="Fim manhã" />
                        <Column dataField="openAfternoon" caption="Ini. tarde" />
                        <Column dataField="closeAfternoon" caption="fim tarde" />
                        <Column dataField="openNight" caption="Ini. noite" />
                        <Column dataField="closeNight" caption="Fim noite" />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsOpening data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>

                    {/* Cadastro de Categorias */}
                    <TabPanel value="4">
                      <Stack direction="row" alignItems="center" justifyContent="end">
                        {!isMobile && (
                          <Grid container>
                            <Grid item>
                              <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                                Adicionar e/ou remover as categorias do parceiro
                              </CustomInfosForm>
                            </Grid>
                          </Grid>
                        )}
                        {!isMobile && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ mb: 1 }}>
                            <Button
                              variant="contained"
                              onClick={LinkItemsModalCategory}
                              startIcon={<Iconify icon="eva:plus-fill" />}>
                              Novo
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={getCategoryEstablishment}
                              sx={{ ml: 1 }}
                              startIcon={<Iconify icon="eva:refresh-outline" />}>
                              Atualizar
                            </Button>
                          </Stack>
                        )}
                        {isMobile && (
                          <Grid item xs={12} sx={{ mb: 3 }}>
                            <Stack direction="row" alignItems="center" justifyContent="end">
                              <Button
                                variant="contained"
                                onClick={LinkItemsModalCategory}
                                startIcon={<Iconify icon="eva:plus-fill" />}>
                                Novo
                              </Button>
                            </Stack>
                          </Grid>
                        )}
                      </Stack>
                      <DataGrid
                        dataSource={infosCategoryEstablishment}
                        showBorders={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        noDataText={'Não há repasses cadastrados'}>
                        <Column dataField="codcategory" caption="cod. Categoria" width={150} />
                        <Column dataField="desccategory" caption="Categoria" />
                        <Column dataField="status" caption="Status" />
                        <Column
                          dataField="Ações"
                          width={100}
                          allowSorting={false}
                          cellRender={(data) => <ActionsButtonsCategory data={data} />}
                        />
                        <SearchPanel visible={true} width={253} placeholder="Procurar..." />
                        {!isMobile && (
                          <GroupPanel
                            visible={true}
                            emptyPanelText="Arraste as colunas aqui para agrupar"
                          />
                        )}
                        <Scrolling rowRenderingMode="virtual"></Scrolling>
                        <Paging defaultPageSize={10} />

                        <Pager
                          visible={true}
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showInfo={true}
                          showNavigationButtons={true}
                        />
                        <ColumnChooser
                          enabled={true}
                          title={'Escolher uma coluna'}
                          emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                        />
                        <ColumnFixing enabled={true} />
                        <HeaderFilter visible={true} allowSearch={true} />
                      </DataGrid>
                    </TabPanel>
                  </TabContext>
                </Box>
              )}
              {/* Botoes de Ação */}
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="success"
                  startIcon={<Iconify icon="eva:save-fill" />}
                  sx={{ mr: 2 }}>
                  {infosPartners.id_establishment ? 'Atualizar' : 'Salvar'}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/dashboard"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default MySpace;
