import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { CustomGrid } from '../../../../assets/style/globalStyle';
import { selectLogin } from '../../../../store/login/loginSlice';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
  Stack,
  Typography,
  Button,
  Card,
  Grid,
  Radio,
  FormControlLabel,
  FormControl
} from '@mui/material';

import MoreMenu from '../../../components/multiplesComponents/MoreMenu';
import Iconify from '../../../../@core/theme/Iconify';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import RenderPage from '../../../layouts/RenderPage';
import configAxios from '../../../../services/configAxios';
import palette from 'src/@core/theme/palette';

import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  GroupPanel,
  Column,
  ColumnChooser,
  ColumnFixing
} from 'devextreme-react/data-grid';

const allowedPageSizes = [5, 10, 20, 30];
const Users = (props) => {
  const infoStateTemp = useSelector(selectLogin);
  const [infosUsers, setInfosUsers] = useState({});
  const [infosPartners, setInfosPartnerts] = useState({});
  const [infosFilter, setInfosFilter] = useState({});
  const [infoState] = useState({ ...infoStateTemp.userLoggedIn[0] });

  useEffect(() => {
    if (infoState.id_type_user === 2) {
      getPartnersUsers();
    } else {
      getAdmUsers();
    }
  }, []);

  function getPartnersUsers() {
    let router;
    if (infoState.id_type_user === 2) {
      router = `/users/listUsers/establishment/${infoState.id_broker}`;
      configAxios
        .get(router)
        .then((response) => {
          setInfosPartnerts(response.data);
        })
        .catch(() => {
          NotificationManager.warning(
            'Tivemos dificuldades para buscar os usuários, por favor, tente novamente!',
            'Atenção'
          );
        });
      return;
    }
  }

  function getAdmUsers() {
    let router;
    router = `/users/listUsers/administrative`;
    configAxios
      .get(router)
      .then((response) => {
        setInfosUsers(response.data);
      })
      .catch(() => {
        NotificationManager.warning(
          'Tivemos dificuldades para buscar os usuários, por favor, tente novamente!',
          'Atenção'
        );
      });
    return;
  }

  function getFilterUsers() {
    if (infosFilter.filterUser) {
      const router =
        infosFilter.filterUser === 'Administrativo'
          ? `/users/listUsers/administrative`
          : `/users/listUsers/establishment`;
      configAxios
        .get(router)
        .then((response) => {
          setInfosUsers(response.data);
        })
        .catch(() => {
          NotificationManager.warning(
            'Tivemos dificuldades para buscar os usuários, por favor, tente novamente!',
            'Atenção'
          );
        });

      return;
    }
  }

  function HandleChange(event) {
    setInfosFilter({ [event.target.name]: event.target.value });
  }

  function ActionsButtons(items) {
    const infos = [];
    infos.push(items.data.data);
    return infos.map((item) => (
      <MoreMenu
        key={item.id}
        infos={{
          id: item.id,
          router: 'editUsers',
          functionGrid: getPartnersUsers,
          otherInfos: item
        }}
      />
    ));
  }

  function ActiveValid(infos) {
    if (infos.data.data.ativo === 'true') {
      return 'Ativo';
    }
    return 'Inativo';
  }

  function Profile(infos) {
    if (infos.data.data.descprofile) {
      return infos.data.data.descprofile;
    }
    return ' - ';
  }

  return (
    <RenderPage title="Usuários">
      {infoState.id_type_user === 2 ? (
        <>
          <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
            <Grid container>
              <BreadcrumbsNav {...props} />
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h4" gutterBottom>
                  Lista de usuários do parceiro
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Utilize as opções abaixo para visualizar os usuários
                </Typography>
              </Grid>
            </Grid>
            <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
              <Button
                variant="contained"
                component={RouterLink}
                to="/app/addUsers"
                startIcon={<Iconify icon="eva:plus-fill" />}>
                Novo
              </Button>
              {!isMobile && (
                <Button
                  variant="outlined"
                  onClick={getPartnersUsers}
                  sx={{ ml: 1 }}
                  startIcon={<Iconify icon="eva:refresh-outline" />}>
                  Atualizar
                </Button>
              )}
            </Stack>
          </Stack>
          <Card>
            <CustomGrid>
              <DataGrid
                dataSource={infosPartners}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                noDataText={'Não foram encontrados usuários'}>
                <Column dataField="id" width={100} />
                <Column dataField="coduser" caption="Cod. do usuário" width={150} />
                <Column dataField="nome" caption="Nome" width={260} />
                <Column dataField="email" width={200} />
                <Column
                  width={140}
                  dataField="descperfil"
                  caption="Perfil"
                  cellRender={(data) => <Profile data={data} />}
                />
                <Column
                  width={100}
                  dataField="ativo"
                  caption="Status"
                  cellRender={(data) => <ActiveValid data={data} />}
                />
                <Column
                  dataField="Ações"
                  width={80}
                  allowSorting={false}
                  cellRender={(data) => <ActionsButtons data={data} />}
                />
                <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                {!isMobile && (
                  <GroupPanel
                    visible={true}
                    emptyPanelText="Arraste as colunas aqui para agrupar"
                  />
                )}
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <ColumnChooser
                  enabled={true}
                  title={'Escolher uma coluna'}
                  emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
              </DataGrid>
            </CustomGrid>
          </Card>
        </>
      ) : (
        <>
          <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
            <Grid container>
              <BreadcrumbsNav {...props} />
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h4" gutterBottom>
                  Lista de usuários
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Utilize as opções abaixo para visualizar os usuários
                </Typography>
              </Grid>
            </Grid>
            <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
              <Button
                variant="contained"
                component={RouterLink}
                to="/app/addUsers"
                startIcon={<Iconify icon="eva:plus-fill" />}>
                Novo
              </Button>
              {!isMobile && (
                <Button
                  variant="outlined"
                  onClick={getAdmUsers}
                  sx={{ ml: 1 }}
                  startIcon={<Iconify icon="eva:refresh-outline" />}>
                  Atualizar
                </Button>
              )}
            </Stack>
          </Stack>
          <Card>
            <CustomGrid>
              <Stack direction="row" alignItems="center" justifyContent="start">
                {infoState.id_type_user === 1 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        style={{ color: palette.primary.contrastText }}
                        variant="h6"
                        gutterBottom>
                        Filtrar usuários
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" variant="standard">
                        <FormControlLabel
                          style={{ color: palette.primary.contrastText }}
                          control={
                            <Radio
                              checked={infosFilter.filterUser === 'Administrativo'}
                              onChange={HandleChange}
                              value="Administrativo"
                              name="filterUser"
                            />
                          }
                          label="Usuários da gestão"
                        />
                      </FormControl>
                      <FormControl component="fieldset" variant="standard">
                        <FormControlLabel
                          style={{ color: palette.primary.contrastText }}
                          control={
                            <Radio
                              checked={infosFilter.filterUser === 'Parceiros'}
                              onChange={HandleChange}
                              value="Parceiros"
                              name="filterUser"
                            />
                          }
                          label="Usuários parceiros"
                        />
                      </FormControl>
                      {isMobile && (
                        <Stack direction="row" alignItems="center" justifyContent="end">
                          <Button
                            variant="contained"
                            onClick={getFilterUsers}
                            sx={{ mr: 1 }}
                            startIcon={<Iconify icon="eva:funnel-fill" />}>
                            Filtrar
                          </Button>
                        </Stack>
                      )}
                      {!isMobile && (
                        <Button
                          variant="contained"
                          onClick={getFilterUsers}
                          sx={{ mr: 1 }}
                          startIcon={<Iconify icon="eva:funnel-fill" />}>
                          Filtrar
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Stack>
            </CustomGrid>
            <CustomGrid>
              <DataGrid
                dataSource={infosUsers}
                showBorders={true}
                remoteOperations={true}
                wordWrapEnabled={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                noDataText={'Não foram encontrados usuários'}>
                <Column dataField="id" width={80} />
                <Column dataField="coduser" width={150} caption="Cod. do usuário" />
                <Column dataField="nome" width={270} caption="Nome" />
                <Column dataField="email" width={200} />
                <Column
                  width={140}
                  dataField="descperfil"
                  caption="Perfil"
                  cellRender={(data) => <Profile data={data} />}
                />
                <Column
                  width={100}
                  dataField="ativo"
                  caption="Status"
                  cellRender={(data) => <ActiveValid data={data} />}
                />
                <Column
                  dataField="Ações"
                  width={100}
                  allowSorting={false}
                  cellRender={(data) => <ActionsButtons data={data} />}
                />
                <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                {!isMobile && (
                  <GroupPanel
                    visible={true}
                    emptyPanelText="Arraste as colunas aqui para agrupar"
                  />
                )}
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <ColumnChooser
                  enabled={true}
                  title={'Escolher uma coluna'}
                  emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                />
                <ColumnFixing enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
              </DataGrid>
            </CustomGrid>
          </Card>
        </>
      )}
    </RenderPage>
  );
};

export default Users;
