/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { Grid, IconButton, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import { CustomInfosForm } from '../../../../assets/style/globalStyle';
import { getDifferenceByArrayAndObjectsProfiles } from '../../../../@core/utils/tablesFunctions';
import DataGrid, {
  Scrolling,
  Paging,
  HeaderFilter,
  Pager,
  SearchPanel,
  Column,
  ColumnChooser,
  ColumnFixing,
  Selection
} from 'devextreme-react/data-grid';

import Iconify from '../../../../@core/theme/Iconify';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';
import palette from 'src/@core/theme/palette';

const allowedPageSizes = [5, 10, 20, 30];

const LinkProfilePermissions = (props) => {
  const [rowsSelected, setRowsSelected] = useState();

  function selectedRows(event) {
    setRowsSelected(event.selectedRowsData);
  }

  function HandleSubmit(event) {
    event.preventDefault();

    if (rowsSelected) {
      const id_profile = props.idProfile;
      const idTypeUser = props.typeUser === 'gestao' ? 1 : 2;

      const infosPermissions = [];

      rowsSelected.forEach((element) => {
        infosPermissions.push({
          name: element.name,
          path: element.path,
          idProfile: id_profile,
          ordem: element.ordem
        });
      });

      const infosPermissionsSubmit = getDifferenceByArrayAndObjectsProfiles(
        infosPermissions,
        props.otherInfos
      );

      if (infosPermissionsSubmit.length === 0) {
        NotificationManager.warning('Não há permissões para serem vinculadas!', 'Atenção');
        return;
      }

      infosPermissionsSubmit.map((element) => {
        element.idTypeUser = idTypeUser;
      });

      configAxios
        .post('/users/linkProfilePermissions', infosPermissionsSubmit)
        .then(() => {
          props.setOpenModal(false);
          props.functionGrid(id_profile);
          NotificationManager.success('Permissões vinculada(s) com sucesso!', 'Sucesso');
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para vincular permissões! Tente novamente!',
            'Atenção'
          );
        });
    } else {
      NotificationManager.warning('Selecione as permissões que deseja vincular!', 'Atenção');
    }
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.setPermissionsProfile({});
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'sm'}
          open={props.openModal}>
          <BootstrapDialogTitle
            style={{ color: palette.primary.contrastText }}
            id="customized-dialog-title"
            onClose={() => {
              props.setOpenModal(false);
              props.setPermissionsProfile({});
              props.functionGrid();
            }}>
            Vincular permissões ao perfil
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <CustomInfosForm sx={{ mb: 2 }} style={{ color: palette.primary.contrastText }}>
                Utilize as opções abaixo para vincular as permissões
              </CustomInfosForm>
              <Grid container sx={{ mb: 0, mt: 0 }}>
                <DataGrid
                  dataSource={props.permissionsProfile}
                  height={310}
                  showBorders={true}
                  remoteOperations={true}
                  wordWrapEnabled={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onSelectionChanged={selectedRows}>
                  <Selection mode="multiple" />
                  <Column dataField="name" caption="Nome" />
                  <Column dataField="path" caption="Caminho" />
                  <SearchPanel visible={true} width={240} placeholder="Procurar..." />
                  <Scrolling rowRenderingMode="virtual"></Scrolling>
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <ColumnChooser
                    enabled={true}
                    title={'Escolher uma coluna'}
                    emptyPanelText={'Arraste uma coluna aqui para ocultá-la '}
                  />
                  <ColumnFixing enabled={true} />
                  <HeaderFilter visible={true} allowSearch={true} />
                </DataGrid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color="success"
                startIcon={<Iconify icon="eva:save-fill" />}>
                Vincular
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  props.setPermissionsProfile({});
                  props.functionGrid();
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default LinkProfilePermissions;
