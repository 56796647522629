/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../../../store/login/loginSlice';
import InputMask from 'react-input-mask';

import { CustomGrid, CustomInfosForm } from '../../../../assets/style/globalStyle';
import { Stack, Typography, Button, Card, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';

import { TextField, Box } from '@mui/material';

import RenderPage from '../../../layouts/RenderPage';
import Iconify from '../../../../@core/theme/Iconify';
import BreadcrumbsNav from '../../../components/multiplesComponents/BreadCrumbs';
import palette from 'src/@core/theme/palette';
import configAxios from 'src/services/configAxios';

const TokenValidation = (props) => {
  const [infosTables, setInfosTables] = useState({});
  const infoState = useSelector(selectLogin);

  function HandleInputChange(event, autocomplete) {
    setInfosTables({ ...infosTables, [event.target.name]: event.target.value.toUpperCase() });
  }

  function HandleSubmit() {
    const infos = {
      ...infosTables,
      idEstablishment: infoState.userLoggedIn[0].id_establishment
    };
    configAxios
      .post('/establishments/validateTokenToEstablishment', infos)
      .then((response) => {
        console.log(response.status);
        if (response.status === 202) {
          NotificationManager.warning(
            'O Parceiro não atende o plano do beneficiário informado no token!',
            'Atenção'
          );
        } else if (response.status === 201) {
          NotificationManager.warning(
            'Token não localizado, ou já utilizado, por favor confirme com o usuário!',
            'Atenção'
          );
        } else if (response.status === 200) {
          NotificationManager.success(
            'Token validado com sucesso, por favor libere a entrada do usuário!',
            'Sucesso'
          );
        }
        setInfosTables({});
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para validar o token, tente novamente mais tarde!',
          'Atenção'
        );
      });
  }

  return (
    <RenderPage title="validationToken">
      <>
        <Stack direction="row" alignItems="center" justifyContent="start" mb={2}>
          <Grid container>
            <BreadcrumbsNav {...props} />
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h4" gutterBottom>
                Validar Token
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Valide abaixo o token do usuário para liberar o acesso do mesmo!
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {!isMobile && (
              <Button
                variant="outlined"
                color="error"
                component={RouterLink}
                to="/app/dashboard"
                startIcon={<Iconify icon="eva:arrow-back-fill" />}>
                Voltar
              </Button>
            )}
          </Stack>
        </Stack>

        <Card>
          <CustomGrid>
            <form onSubmit={() => HandleSubmit()}>
              <Box sx={{ width: '100%', mt: 1, border: 1, borderColor: 'grey.200', p: 2 }}>
                <CustomInfosForm style={{ color: palette.primary.contrastText }}>
                  Campos obrigatórios possuem asterisco (*)
                </CustomInfosForm>
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 1, input: { color: palette.primary.contrastText } }}>
                  <Grid item xs={12} md={3}>
                    <InputMask
                      mask="****-****"
                      value={infosTables.token || ''}
                      onChange={(event) => HandleInputChange(event)}>
                      {() => (
                        <TextField
                          name="token"
                          fullWidth
                          label="Token do usuário"
                          required
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Stack direction="row" justifyContent="end" sx={{ ml: 4 }}>
                    <Button
                      onClick={HandleSubmit}
                      variant="contained"
                      size="medium"
                      color="success"
                      sx={{ mt: 1.7 }}
                      style={{ height: 40 }}
                      startIcon={<Iconify icon="eva:search-outline" />}>
                      {'validar Token'}
                    </Button>
                  </Stack>
                </Grid>
              </Box>
              <Stack direction="row" justifyContent="end" sx={{ mt: 5 }}>
                <Button
                  variant="contained"
                  color="error"
                  component={RouterLink}
                  to="/app/dashboard"
                  startIcon={<Iconify icon="eva:close-circle-fill" />}>
                  Cancelar
                </Button>
              </Stack>
            </form>
          </CustomGrid>
        </Card>
      </>
    </RenderPage>
  );
};

export default TokenValidation;
