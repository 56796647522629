/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BootstrapDialog } from '../../../../assets/style/globalStyle';
import { IconButton, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { NotificationManager } from 'react-notifications';
import { TextField, Grid, Autocomplete } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import configAxios from '../../../../services/configAxios';
import Iconify from '../../../../@core/theme/Iconify';
import palette from 'src/@core/theme/palette';

const LinkTransfers = (props) => {
  const [infosPlans, setInfosPlans] = useState([]);
  const [infosTransfersPreCad, setInfosTransfersPreCad] = useState([]);
  const [infosTransfers, setInfosTransfers] = useState([]);
  const [valTransfer, setValTransfer] = useState([]);

  useEffect(() => {
    getPlans();
    getTransfers();
  }, []);

  function getPlans() {
    configAxios
      .get('/products/getProductsLabel')
      .then((response) => {
        setInfosPlans(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos! Tente novamente!',
          'Atenção'
        );
      });
  }

  function getTransfers() {
    configAxios
      .get('/transfers/getTransfersLabel')
      .then((response) => {
        setInfosTransfersPreCad(response.data);
      })
      .catch(() => {
        NotificationManager.error(
          'Tivemos dificuldades para buscar os planos! Tente novamente!',
          'Atenção'
        );
      });
  }

  function HandleInputChange(event, autocomplete) {
    if (autocomplete) {
      if (event.codplano) {
        setInfosTransfers({
          ...infosTransfers,
          idPlan: event.id,
          idEstablishment: props.otherInfos.id
        });
        setValTransfer({ ...valTransfer, PlanLabel: event.label });
        return;
      } else if (event.codtransfers) {
        setInfosTransfers({
          ...infosTransfers,
          idTransfers: event.id
        });
        setValTransfer({
          ...valTransfer,
          TransferLabel: event.label,
          TransferValue: event.transfervalue
        });
        return;
      }
      return;
    }
    setInfosTransfers({ ...infosTransfers, [event.target.name]: event.target.value });
  }

  function HandleSubmit(event) {
    event.preventDefault();

    if (infosTransfers) {
      configAxios
        .post('/establishments/linkTransfersToEstablishment', infosTransfers)
        .then(() => {
          props.setOpenModal(false);
          props.functionGrid();
          NotificationManager.success('Repasse vinculado com sucesso!', 'Sucesso');
          setInfosTransfers({});
          setValTransfer({});
          setInfosTransfersPreCad({});
        })
        .catch(() => {
          NotificationManager.error(
            'Tivemos dificuldades para vincular os repassess! Tente novamente!',
            'Atenção'
          );
        });
    } else {
      NotificationManager.warning('Selecione o plano e o valor que deseja vincular!', 'Atenção');
    }
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 10,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  };

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => {
            props.setOpenModal(false);
            props.functionGrid();
          }}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth={'md'}
          open={props.openModal}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            style={{ color: palette.primary.contrastText }}
            onClose={() => {
              props.setOpenModal(false);
              props.functionGrid();
            }}>
            Vincular repasses no parceiro
          </BootstrapDialogTitle>
          <form onSubmit={HandleSubmit}>
            <DialogContent dividers>
              <Grid
                container
                spacing={2}
                sx={{
                  mt: 1,
                  input: { color: palette.primary.contrastText }
                }}>
                <Grid item xs={5} md={4}>
                  <Autocomplete
                    sx={{ color: palette.primary.contrastText, input: { color: 'black' } }}
                    options={infosPlans}
                    renderOption={(props, option) => (
                      <li style={{ color: palette.primary.contrastText }} {...props}>
                        {option.label}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputChange(value, { autocomplete: true })}
                    value={valTransfer.PlanLabel || ''}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField
                        sx={{ color: palette.primary.contrastText, input: { color: 'black' } }}
                        {...params}
                        label="Plano"
                        name="PlanLabel"
                        fullWidth
                        size="small"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Grid>
                {/* grid do repasse */}
                <Grid item xs={5} md={4}>
                  <Autocomplete
                    sx={{ color: palette.primary.contrastText, input: { color: 'black' } }}
                    options={infosTransfersPreCad}
                    renderOption={(props, option) => (
                      <li style={{ color: palette.primary.contrastText }} {...props}>
                        {option.label}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      value ? option.label === value : option.label === ''
                    }
                    onChange={(event, value) => HandleInputChange(value, { autocomplete: true })}
                    value={valTransfer.TransferLabel || ''}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField
                        sx={{ color: palette.primary.contrastText, input: { color: 'black' } }}
                        {...params}
                        label="Repasse"
                        name="TransferLabel"
                        fullWidth
                        size="small"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ color: palette.primary.contrastText, input: { color: 'black' } }}>
                  <TextField
                    name="TransferValue"
                    fullWidth
                    label="Val. repasse"
                    variant="outlined"
                    size="small"
                    required
                    value={valTransfer.TransferValue || ''}
                    disabled
                    onChange={(event) => HandleInputChange(event)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color="success"
                startIcon={<Iconify icon="eva:save-fill" />}>
                Vincular
              </Button>
              <Button
                onClick={() => {
                  props.setOpenModal(false);
                  props.functionGrid();
                }}
                color="error"
                variant="contained"
                startIcon={<Iconify icon="eva:close-circle-fill" />}>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default LinkTransfers;
