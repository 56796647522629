const permissions = () => [
  {
    ordem: 0,
    name: 'Dashboard',
    path: '/app/dashboard',
    typeUser: 'parceiro'
  },
  {
    ordem: 1,
    name: 'Minha conta',
    path: '/app/myAccount',
    typeUser: 'parceiro'
  },
  {
    ordem: 2,
    name: 'Validar Token',
    path: '/app/TokenValidation',
    typeUser: 'parceiro'
  },
  {
    ordem: 3,
    name: 'Usuários',
    path: '/app/users',
    typeUser: 'parceiro'
  },
  {
    ordem: 4,
    name: 'Permissões padrões',
    path: '/app/permissions',
    typeUser: 'gestao'
  },
  {
    ordem: 5,
    name: 'Associados',
    path: '/app/clients',
    typeUser: 'gestao'
  },
  {
    ordem: 6,
    name: 'Parceiros',
    path: '/app/partners',
    typeUser: 'gestao'
  },
  {
    ordem: 7,
    name: 'Empresas',
    path: '/app/companies',
    typeUser: 'gestao'
  },
  {
    ordem: 8,
    name: 'Comodidades',
    path: '/app/Amenities',
    typeUser: 'gestao'
  },
  {
    ordem: 9,
    name: 'Categorias',
    path: '/app/category',
    typeUser: 'gestao'
  },
  {
    ordem: 10,
    name: 'Avisos',
    path: '/app/warnings',
    typeUser: 'gestao'
  },
  {
    ordem: 11,
    name: 'Formulários',
    path: '/app/forms',
    typeUser: 'gestao'
  },
  {
    ordem: 12,
    name: 'Meu espaço',
    path: '/app/MySpace',
    typeUser: 'parceiro'
  },
  {
    ordem: 13,
    name: 'Produtos',
    path: '/app/products',
    typeUser: 'gestao'
  },
  {
    ordem: 14,
    name: 'Tabelas',
    path: '/app/tables',
    typeUser: 'gestao'
  },
  {
    ordem: 15,
    name: 'Repasses',
    path: '/app/transfers',
    typeUser: 'gestao'
  },
  {
    ordem: 16,
    name: 'Reajustes',
    path: '/app/readjustments',
    typeUser: 'gestao'
  },
  {
    ordem: 17,
    name: 'Fornecedores',
    path: '/app/suppliers',
    typeUser: 'gestao'
  },
  {
    ordem: 18,
    name: 'Contas a pagar',
    path: '/app/billsToPay',
    typeUser: 'gestao'
  },
  {
    ordem: 19,
    name: 'Contas a receber',
    path: '/app/billsToReceive',
    typeUser: 'gestao'
  },
  {
    ordem: 20,
    name: 'Meu Extrato',
    path: '/app/myFinancialStatement',
    typeUser: 'parceiro'
  },
  {
    ordem: 21,
    name: 'Competências',
    path: '/app/competence',
    typeUser: 'gestao'
  },
  {
    ordem: 22,
    name: 'Gerar Faturamento',
    path: '/app/generateBilling',
    typeUser: 'gestao'
  },
  {
    ordem: 23,
    name: 'Manutenção de Faturamento',
    path: '/app/maintenanceBilling',
    typeUser: 'gestao'
  },
  {
    ordem: 24,
    name: 'SAC',
    path: '/app/openSAC',
    typeUser: 'gestao'
  },
  {
    ordem: 25,
    name: 'Tokens Utilizados',
    path: '/app/reports/RepUsedTokens',
    typeUser: 'parceiro'
  },
  {
    ordem: 26,
    name: 'Relatório de vendas',
    path: '/app/reports/repSales',
    typeUser: 'gestao'
  },
  {
    ordem: 27,
    name: 'Relatório de simulações',
    path: '/app/reports/repSimulations',
    typeUser: 'gestao'
  },
  {
    ordem: 28,
    name: 'Configurações do sistema',
    path: '/app/config',
    typeUser: 'gestao'
  }
];

export default permissions;
